import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"mx-auto",attrs:{"sm":"8","md":"8","lg":"6","xl":"4"}},[_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require('../assets/lamiapratica_nero.png'),"height":"30px","alt":""}})]),_c(VCard,{staticClass:"mx-auto pa-4 pa-sm-6 pa-md-16 my-4",staticStyle:{"max-width":"600px"},attrs:{"elevation":"6"}},[_c(VCardText,[_c(VRow,[_c(VCol,[_c('h2',{staticClass:"mb-2 text-center"},[_vm._v(" Oops! Qualcosa è andato storto... ")])])],1)],1),_c(VCardActions,{staticClass:"d-flex justify-center"},[_c(VBtn,{attrs:{"color":"primary","loading":_vm.loading.refresh},on:{"click":_vm.tryRefresh}},[_vm._v(" Ricarica ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }